import * as amplitude from "@amplitude/analytics-browser";
import React from "react";
import { getStorage } from "../../data/getStorage";
import { useDecisionState } from "../../state/CurrentDecisionState";
import { InitialInstructions } from "./InitialInstructions";

export function InitialInstructionsSection() {
  const numberOfDecisionOptions = useDecisionState((s) => s.numberOfOptions());
  const hasDecided = useDecisionState((s) => s.hasDecided());
  const setOptionList = useDecisionState((s) => s.setOptionList);

  return (
    <div
      style={{
        display: numberOfDecisionOptions > 0 || hasDecided ? "none" : "block",
      }}
    >
      <InitialInstructions
        onListSelected={(list) => {
          setOptionList(list);
          amplitude.track("Click Load List");
        }}
        onListDelete={(list) => {
          amplitude.track("Delete List", { itemsCount: list.options.length });
          return getStorage().then((s) => s.deleteList(list.id!));
        }}
      />
    </div>
  );
}
