import { useIonToast } from "@ionic/react";
import { warning } from "ionicons/icons";
import React from "react";
import { useDecisionState } from "../../state/CurrentDecisionState";
import { ShuffleButton } from "./ShuffleButton";

export interface DecideButtonSectionProps {
  onShuffled?: () => void;
}

export function ShuffleButtonSection({ onShuffled }: DecideButtonSectionProps) {
  const [toast] = useIonToast();

  const hasDecided = useDecisionState((s) => s.hasDecided());
  const numberOfDecisionOptions = useDecisionState((s) => s.numberOfOptions());
  const setIsDeciding = useDecisionState((s) => s.setIsDeciding);

  if (hasDecided) {
    return null;
  }

  const onShuffle = () => {
    if (numberOfDecisionOptions < 2) {
      void toast({
        id: "not-enough-options",
        duration: 2000,
        message: "You need at least 2 options to decide",
        position: "top",
        icon: warning,
        color: "warning",
      });
      return;
    }

    setIsDeciding(true);
    onShuffled?.();
  };

  return (
    <ShuffleButton onClick={onShuffle} disabled={numberOfDecisionOptions < 2} />
  );
}
