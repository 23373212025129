import { IonButton, IonIcon, IonInput, IonItem } from "@ionic/react";
import { add } from "ionicons/icons";
import React from "react";

export interface NewOptionFormProps {
  onSubmit: (data: { name: string }) => void;
  disabled: boolean;
}

export function NewOptionForm({ onSubmit, disabled }: NewOptionFormProps) {
  const [newDecisionInputValue, setNewDecisionInputValue] =
    React.useState<string>("");

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        if (disabled || !newDecisionInputValue) {
          return;
        }

        onSubmit({ name: newDecisionInputValue });

        setNewDecisionInputValue("");
      }}
    >
      <IonItem>
        <IonInput
          enterkeyhint="next"
          disabled={disabled}
          onIonChange={(e) => setNewDecisionInputValue(e.detail.value ?? "")}
          value={newDecisionInputValue}
          placeholder={"Add an option"}
          autofocus
          type="text"
        />
        <IonButton
          disabled={!newDecisionInputValue || disabled}
          fill="outline"
          color="dark"
          slot={"end"}
          type="submit"
        >
          <IonIcon slot="start" icon={add} />
          Add
        </IonButton>
      </IonItem>
    </form>
  );
}
