import {
  IonButton,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonListHeader,
} from "@ionic/react";
import clsx from "clsx";
import { trash } from "ionicons/icons";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { Option } from "../../data/Decision";
import { SHUFFLE_PERIOD_MS } from "./Constants";

import classes from "./OptionsList.module.css";
import { useShuffleItems } from "./useShuffleItems";

export interface OptionsListProps {
  options: Option[];
  title?: string;
  onItemDelete?: (item: Option) => void;
  onDecide?: (item: Option) => void;
  isDeciding?: boolean;
}

export interface OptionsListHandle {
  shuffleItems: () => void;
}

const ITEM_MARGIN_PX = 4;
const ITEM_HEIGHT_PX = 45 + ITEM_MARGIN_PX * 2;
const MARGIN_BOTTOM_PX = 126;

export const OptionsList = forwardRef<OptionsListHandle, OptionsListProps>(
  ({ options, onItemDelete, onDecide, isDeciding, title }, ref) => {
    const itemRefs = useRef<HTMLElement[]>([]);

    useImperativeHandle(ref, () => ({ shuffleItems }));

    const { shuffleItems, isShuffling } = useShuffleItems({ itemRefs });

    useScrollToBottomOnNewItem(
      options.length,
      itemRefs?.current?.[itemRefs.current?.length - 1]
    );

    const hideText = isDeciding || isShuffling;

    const resetItemPositions = () =>
      itemRefs.current.forEach(
        (ref, i) =>
          (ref.style.bottom = `${(options.length - i - 1) * ITEM_HEIGHT_PX}px`)
      );

    const onItemClick = (option: Option) => {
      if (!isDeciding || isShuffling) {
        return;
      }

      onDecide?.(option);
      resetItemPositions();
    };

    return (
      <>
        <IonList
          inset
          lines="none"
          className={classes.list}
          style={{
            height: `${options.length * ITEM_HEIGHT_PX + MARGIN_BOTTOM_PX}px`,
          }}
        >
          {title && <IonListHeader>{title}</IonListHeader>}

          {options.map((option, i) => {
            const onDelete = () => onItemDelete?.(option);

            return (
              <div
                ref={(e) => e && (itemRefs.current[i] = e)}
                key={option.id}
                className={clsx(classes.item, {
                  // [classes.afterShuffle]: !isShuffling && isDeciding,
                })}
                style={{
                  bottom: `${
                    MARGIN_BOTTOM_PX + (options.length - i - 1) * ITEM_HEIGHT_PX
                  }px`,
                  height: `${ITEM_HEIGHT_PX}px`,
                  margin: `${ITEM_MARGIN_PX}px 0`,
                  transition: `bottom ${SHUFFLE_PERIOD_MS}ms ease-in-out`,
                }}
              >
                <IonItemSliding disabled={isShuffling || isDeciding}>
                  <IonItem
                    lines="none"
                    color="light"
                    onClick={() => onItemClick(option)}
                    style={{ "--border-width": 0 }}
                  >
                    <IonLabel className="truncate">
                      {hideText ? <>&nbsp;</> : option.name}
                    </IonLabel>

                    {onItemDelete && (
                      <IonButton
                        size="small"
                        fill="clear"
                        slot="end"
                        color="medium"
                        aria-label={`Remove option ${option.name}`}
                        onClick={onDelete}
                        style={{ display: isDeciding ? "none" : "block" }}
                      >
                        <IonIcon size="small" slot={"icon-only"} icon={trash} />
                      </IonButton>
                    )}
                  </IonItem>

                  {onItemDelete && (
                    <IonItemOptions onIonSwipe={onDelete} side="end">
                      <IonItemOption
                        disabled={isDeciding}
                        onClick={onDelete}
                        color="danger"
                        expandable
                      >
                        <IonIcon slot="start" icon={trash} />
                        Delete
                      </IonItemOption>
                    </IonItemOptions>
                  )}
                </IonItemSliding>
              </div>
            );
          })}
        </IonList>
      </>
    );
  }
);

function useScrollToBottomOnNewItem(currentCount: number, el?: HTMLElement) {
  let prevCountRef = useRef(0);

  useEffect(() => {
    if (currentCount > prevCountRef.current)
      el?.scrollIntoView({
        behavior: "smooth",
      });
    prevCountRef.current = currentCount;
  }, [currentCount, el]);
}
