import { IonItem, IonLabel, IonList } from "@ionic/react";
import React, { useMemo } from "react";
import classes from "./DecidedItem.module.css";

export interface DecidedItemProps {
  item: string;
}

const titles = [
  <>The decision is</>,
  <>
    It has been decided{" "}
    <span role="img" aria-label="thumbs up">
      🎉
    </span>
  </>,
  "No going back now",
  <>
    The universe has spoken{" "}
    <span role="img" aria-label="thumbs up">
      🌌
    </span>
  </>,
  "This is your sign",
  "It is known",
];

export function DecidedItem({ item }: DecidedItemProps) {
  const title = useMemo(
    () => titles[Math.floor(Math.random() * titles.length)],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item]
  );

  return (
    <>
      <IonLabel
        style={{ margin: "1.5rem", textAlign: "center", display: "block" }}
      >
        {title}
      </IonLabel>

      <IonList inset className={classes.decidedItem}>
        <IonItem>
          <IonLabel>{item}</IonLabel>
        </IonItem>
      </IonList>
    </>
  );
}
