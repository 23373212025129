import { IonButton, IonIcon, useIonAlert } from "@ionic/react";
import { save } from "ionicons/icons";
import React from "react";
import { IS_APP } from "../../utils/isApp";

export interface SaveListButtonProps {
  defaultName?: string;
  onSave: ({ name }: { name: string }) => Promise<void>;
  toUpdate?: boolean;
}

export function SaveListButton({
  onSave,
  defaultName,
  toUpdate,
}: SaveListButtonProps) {
  const [presentAlert] = useIonAlert();

  return (
    <IonButton
      size="small"
      onClick={() => {
        return presentAlert({
          header: toUpdate ? "Update saved list" : "Save list for later",
          message: !IS_APP
            ? "NOTE: Your browser may clear this data sometimes. To persist lists safely download our mobile app"
            : undefined,
          buttons: [
            {
              text: "Save",
              handler: ({ name }) => {
                if (!name) {
                  return false;
                }

                return onSave({ name });
              },
            },
          ],
          inputs: [{ placeholder: "Name", name: "name", value: defaultName }],
        });
      }}
    >
      <IonIcon size="small" icon={save} slot="start" /> Save
    </IonButton>
  );
}
