import * as amplitude from "@amplitude/analytics-browser";
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { information } from "ionicons/icons";
import React, { useRef } from "react";
import { PrivacyPolicy } from "./PrivacyPolicy";

export function AboutButton() {
  const modal = useRef<HTMLIonModalElement>(null);

  return (
    <>
      <IonButton
        onClick={() => amplitude.track("Click About Button")}
        id="open-about-modal"
        size="small"
      >
        <IonIcon size="small" icon={information} slot="start" /> About
      </IonButton>

      <IonModal ref={modal} trigger="open-about-modal">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => modal.current?.dismiss()}>
                Close
              </IonButton>
            </IonButtons>
            <IonTitle>About</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <h2>Just Decide</h2>
          <h3>Random Decision Maker</h3>
          <p>Made by Anton Mihaylov | Antomic Studio</p>
          <p>©2022</p>
          <a href="mailto:anton@antomic.net?subject=Just%20Decide">Contact</a>

          <IonAccordionGroup>
            <IonAccordion style={{ marginTop: "1rem" }}>
              <IonItem slot="header" color="light">
                <IonLabel>Privacy policy</IonLabel>
              </IonItem>
              <div className="ion-padding" slot="content">
                <PrivacyPolicy />
              </div>
            </IonAccordion>
          </IonAccordionGroup>
        </IonContent>
      </IonModal>
    </>
  );
}
