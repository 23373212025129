import create from "zustand";
import {
  addOption,
  AddOptionInput,
  createDecision,
  Decision,
  Option,
  OptionsList,
  removeOption,
  setDecidedOption,
} from "../data/Decision";

export interface CurrentDecisionState {
  decision: Decision | null;

  isDeciding: boolean;

  addOption: (input: AddOptionInput) => void;
  removeOption: (optionOrId: number | Option) => void;
  setDecidedOption: (optionOrId: number | Option) => void;
  reset: () => void;
  again: () => void;

  updateOptionList: (
    data: Pick<OptionsList, "name" | "id" | "firstSavedOn">
  ) => void;
  setIsDeciding: (isDeciding: boolean) => void;
  setOptionList: (optionList: OptionsList) => void;

  hasDecided(): boolean;
  numberOfOptions(): number;
}

export const useDecisionState = create<CurrentDecisionState>((set, get) => {
  return {
    decision: null,
    isDeciding: false,

    updateOptionList: (
      data: Pick<OptionsList, "name" | "id" | "firstSavedOn">
    ) => {
      set((state) => ({
        ...state,
        decision: state.decision
          ? {
              ...state.decision,
              options: { ...state.decision.options, ...data },
            }
          : createDecision({ options: { options: [], ...data } }),
      }));
    },
    setOptionList: (list: OptionsList) => {
      set((state) => ({
        ...state,
        decision: state.decision
          ? { ...state.decision, options: list }
          : createDecision({ options: list }),
      }));
    },

    hasDecided() {
      const { decision } = get();
      return !!decision && !!decision?.decidedItemId;
    },

    numberOfOptions() {
      return get().decision?.options.options.length ?? 0;
    },

    addOption: (input) =>
      set((state) => {
        const decision = state.decision ?? createDecision();
        return { ...state, decision: addOption(decision, input) };
      }),

    removeOption: (optionOrId) =>
      set((state) => {
        if (!state.decision) {
          return state;
        }

        return {
          ...state,
          decision: removeOption(state.decision, optionOrId),
        };
      }),

    reset: () =>
      set((state) => ({
        ...state,
        decision: null,
        isDeciding: false,
      })),

    again: () =>
      set((state) => ({
        ...state,
        decision: createDecision({ options: state.decision?.options }),
        isDeciding: false,
      })),

    setIsDeciding: (isDeciding) => set((state) => ({ ...state, isDeciding })),

    setDecidedOption: (optionOrId) =>
      set((state) => {
        if (!state.decision || !!state.decision.decidedItemId) {
          return { ...state, isDeciding: false };
        }

        return {
          ...state,
          decision: setDecidedOption(state.decision, optionOrId),
          isDeciding: false,
        };
      }),
  };
});
