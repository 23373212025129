import { IonButton, IonIcon } from "@ionic/react";
import { refresh } from "ionicons/icons";
import React from "react";

export interface ResetListButtonProps {
  onReset: () => void;
}

export function ResetListButton({ onReset }: ResetListButtonProps) {
  return (
    <IonButton size="small" onClick={onReset}>
      <IonIcon size="small" icon={refresh} slot="start" /> Reset
    </IonButton>
  );
}
