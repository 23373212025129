import { v4 } from "uuid";

export interface Option {
  id: number;
  name: string;
}

export interface OptionsList {
  id?: string;
  name?: string;
  firstSavedOn?: number;
  options: Option[];
}

export interface Decision {
  id: string;
  options: OptionsList;
  decidedItemId?: number;
}

export function createDecision(decision?: Partial<Decision>): Decision {
  return {
    id: v4(),
    options: decision?.options ?? { options: [] },
    decidedItemId: decision?.decidedItemId ?? undefined,
  };
}

export function getUniqueOptionId(options: Option[]): number {
  let greatestId = 0;

  for (let option of options) {
    if (option.id > greatestId) {
      greatestId = option.id;
    }
  }

  return greatestId + 1;
}

export interface AddOptionInput {
  name: string;
}

export function addOption(
  decision: Decision,
  { name }: AddOptionInput
): Decision {
  return {
    ...decision,
    options: {
      ...decision.options,
      options: [
        ...decision.options.options,
        { id: getUniqueOptionId(decision.options.options), name },
      ],
    },
  };
}

export function removeOption(
  decision: Decision,
  optionOrId: number | Option
): Decision {
  const optionId = typeof optionOrId === "number" ? optionOrId : optionOrId.id;

  return {
    ...decision,
    options: {
      ...decision.options,
      options: decision.options.options.filter(
        (option) => option.id !== optionId
      ),
    },
  };
}

export function setDecidedOption(
  decision: Decision,
  optionOrId: number | Option
): Decision {
  const optionId = typeof optionOrId === "number" ? optionOrId : optionOrId.id;

  return { ...decision, decidedItemId: optionId };
}
