import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import React from 'react'
import { Route } from 'react-router'
import { AppTermsOfUse } from './pages/AppTermsOfUse'
import DecidePage from './pages/DecidePage'
import * as amplitude from '@amplitude/analytics-browser'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './theme/global.css'
import { AppPrivacyPolicy } from './pages/AppPrivacyPolicy'

amplitude.init('056d774c35a9835e73002f813c21678c')

setupIonicReact()

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route path="/" component={DecidePage} />
          <Route path="/app/privacy-policy" component={AppPrivacyPolicy} />
          <Route path="/app/terms-of-use" component={AppTermsOfUse} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  )
}

export default App
