import {
  IonButton,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList,
  IonNote,
  IonSpinner,
  useIonAlert,
} from "@ionic/react";
import { trash } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { OptionsList } from "../../data/Decision";
import { getStorage } from "../../data/getStorage";
import { IS_APP } from "../../utils/isApp";

export interface LoadOptionsListProps {
  onListSelected: (list: OptionsList) => void;
  onListDelete: (list: OptionsList) => Promise<void>;
}

export function LoadOptionsList({
  onListSelected,
  onListDelete,
}: LoadOptionsListProps) {
  const [presentAlert] = useIonAlert();

  const [lists, setLists] = useState<OptionsList[]>();
  const [isLoading, setIsLoading] = useState(false);

  const fetch = () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    (async () => {
      const storage = await getStorage();
      const lists = await storage.getLists();
      setLists(lists);
      setIsLoading(false);
    })().catch((e) => presentAlert({ header: "Error", message: e.message }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetch(), []);

  return (
    <IonList>
      {!IS_APP && (
        <IonNote style={{ display: "block", textAlign: "center" }}>
          NOTE: Your browser may clear your data sometimes. To persist lists
          safely download our mobile app
        </IonNote>
      )}
      {isLoading ? (
        <IonSpinner />
      ) : (
        <>
          {!!lists?.length ? (
            lists.map((list) => {
              const onDelete = () => {
                onListDelete(list).then(() => {
                  setLists((lists) => lists?.filter((l) => l.id !== list.id));
                  fetch();
                });
              };
              return (
                <IonItemSliding key={list.id}>
                  <IonItem onClick={() => onListSelected(list)}>
                    {list.name}
                    <IonButton
                      size="small"
                      fill="clear"
                      slot="end"
                      color="medium"
                      aria-label={`Delete list ${list.name}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        onDelete();
                      }}
                    >
                      <IonIcon size="small" slot={"icon-only"} icon={trash} />
                    </IonButton>
                  </IonItem>

                  <IonItemOptions onIonSwipe={onDelete} side="end">
                    <IonItemOption onClick={onDelete} color="danger" expandable>
                      <IonIcon slot="start" icon={trash} />
                      Delete
                    </IonItemOption>
                  </IonItemOptions>
                </IonItemSliding>
              );
            })
          ) : (
            <IonNote style={{ textAlign: "center", display: "block" }}>
              No lists saved
            </IonNote>
          )}
        </>
      )}
    </IonList>
  );
}
