import { IonContent, IonPage } from '@ionic/react'
import React from 'react'
import classes from './PrivacyPages.module.css'

export interface AppTermsOfUseProps {}

export function AppTermsOfUse(props: AppTermsOfUseProps) {
  return (
    <IonPage>
      <IonContent>
        <div className={classes.pageContainer}>
          <div className={classes.pageContainerInner}>
            <h1>Terms of Use for Just Decide</h1>
            <p>
              <strong>Effective Date: 18th May 2024</strong>
            </p>
            <p>
              Welcome to Just Decide! These Terms of Use govern your access to
              and use of the Just Decide app, developed and maintained by
              Antomic Studio. By downloading, accessing, or using our app, you
              agree to be bound by these terms. If you do not agree with our
              terms, please do not use the Just Decide app.
            </p>
            <h2>1. Acceptance of Terms</h2>
            <p>
              By using Just Decide, you confirm that you have read, understood,
              and agreed to these Terms of Use. You also agree to our Privacy
              Policy, which covers how we collect, use, and share your
              information.
            </p>
            <h2>2. Eligibility</h2>
            <p>
              Just Decide is intended for users who are at least 14 years old.
              By using Just Decide, you represent and warrant that you are of
              legal age to form a binding contract with Antomic Studio.
            </p>
            <h2>3. App License</h2>
            <p>
              Subject to your compliance with these terms, Antomic Studio grants
              you a limited, non-exclusive, non-transferable, revocable license
              to download and use Just Decide for your personal, non-commercial
              use on a device owned or otherwise controlled by you.
            </p>
            <h2>4. Prohibited Uses</h2>
            <p>In using Just Decide, you agree not to:</p>
            <ul>
              <li>Use the app for any illegal or unauthorized purpose.</li>
              <li>
                Attempt to gain unauthorized access to our servers or networks.
              </li>
              <li>
                Transmit any viruses, malware, or other types of malicious
                software.
              </li>
              <li>
                Engage in any activity that disrupts or interferes with the
                functioning of the app.
              </li>
              <li>
                Reproduce, duplicate, copy, sell, resell or exploit any portion
                of Just Decide without express permission by Antomic Studio.
              </li>
            </ul>
            <h2>5. Intellectual Property Rights</h2>
            <p>
              All rights, title, and interest in and to Just Decide (excluding
              content provided by users) are and will remain the exclusive
              property of Antomic Studio and its licensors. The terms Just
              Decide, the Just Decide logo, and all related names, logos,
              product and service names, designs, and slogans are trademarks of
              Antomic Studio or its affiliates or licensors. You must not use
              such marks without the prior written permission of Antomic Studio.
            </p>
            <h2>6. Termination</h2>
            <p>
              We may terminate or suspend your access to Just Decide
              immediately, without prior notice or liability, for any reason
              whatsoever, including, without limitation, if you breach these
              Terms of Use. Upon termination, your right to use Just Decide will
              immediately cease.
            </p>
            <h2>7. Disclaimers</h2>
            <p>
              Just Decide and its associated services are provided on an "as is"
              and "as available" basis. Antomic Studio does not warrant that the
              app or any of its functions will be uninterrupted or error-free,
              that defects will be corrected, or that any part of the app, or
              the servers that make the app available, are free of viruses or
              other harmful components.
            </p>
            <h2>8. Limitation of Liability</h2>
            <p>
              To the maximum extent permitted by law, in no event shall Antomic
              Studio, its affiliates, agents, directors, employees, suppliers,
              or licensors be liable for any indirect, punitive, incidental,
              special, consequential, or exemplary damages, including without
              limitation damages for loss of profits, goodwill, use, data, or
              other intangible losses that result from the use of, or inability
              to use, this service.
            </p>
            <h2>9. Changes to Terms</h2>
            <p>
              Antomic Studio reserves the right to modify or replace these Terms
              of Use at any time at its sole discretion. We will provide notice
              of these changes by updating the effective date at the top of this
              page.
            </p>
            <h2>10. Contact Information</h2>
            <p>
              If you have any questions about these Terms of Use, please contact
              us at:
            </p>
            <p>
              Email: <a href="mailto:info@antomic.net">info@antomic.net</a>
            </p>
            <p>
              Thank you for choosing Just Decide. We hope it helps make your
              decision-making process smoother and more enjoyable!
            </p>
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}
