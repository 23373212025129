import * as amplitude from "@amplitude/analytics-browser";
import React from "react";
import { useDecisionState } from "../../state/CurrentDecisionState";
import { DecidedButtons } from "./DecidedButtons";

export function DecidedButtonsSection() {
  const hasDecided = useDecisionState((s) => s.hasDecided());
  const reset = useDecisionState((s) => s.reset);
  const again = useDecisionState((s) => s.again);

  if (!hasDecided) {
    return null;
  }

  return (
    <DecidedButtons
      onRefresh={() => {
        again();
        amplitude.track("Repeat Decision");
      }}
      onNewDecision={() => {
        reset();
      }}
    />
  );
}
