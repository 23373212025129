import * as amplitude from "@amplitude/analytics-browser";
import React, { forwardRef } from "react";
import { useDecisionState } from "../../state/CurrentDecisionState";
import { OptionsList, OptionsListHandle } from "./OptionsList";

export const OptionsListSection = forwardRef<OptionsListHandle>(
  (props, ref) => {
    const { decision, isDeciding, setDecidedOption, removeOption } =
      useDecisionState();

    if (
      !decision ||
      !decision.options?.options?.length ||
      decision.decidedItemId
    ) {
      return null;
    }

    return (
      <OptionsList
        ref={ref}
        title={decision.options.name}
        key={decision.id}
        isDeciding={isDeciding}
        onItemDelete={removeOption}
        onDecide={(option) => {
          setDecidedOption(option);
          amplitude.track("Decided");
        }}
        options={decision.options.options}
      />
    );
  }
);
