import React from "react";
import { useDecisionState } from "../../state/CurrentDecisionState";
import { ResetListButton } from "./ResetListButton";

export interface SaveListButtonSectionProps {}

export function ResetListButtonSection(props: SaveListButtonSectionProps) {
  const { reset, decision } = useDecisionState();

  if (!decision) {
    return null;
  }

  return (
    <ResetListButton
      onReset={() => {
        reset();
      }}
    />
  );
}
