import { Drivers, Storage } from "@ionic/storage";
import { IS_APP } from "../utils/isApp";

import { IListStorage } from "./ListStorage";
import { LocalListStorage } from "./LocalListStorage";

let storage: IListStorage | undefined;

export async function getStorage(): Promise<IListStorage> {
  if (storage) {
    return storage;
  }

  const drivers = [Drivers.IndexedDB, Drivers.LocalStorage];

  let CordovaSQLiteDriver: LocalForageDriver | undefined;

  if (IS_APP) {
    CordovaSQLiteDriver = await import("localforage-cordovasqlitedriver");
    drivers.unshift(CordovaSQLiteDriver._driver);
  }

  const store = new Storage({
    name: "option_lists",
    driverOrder: drivers,
    version: 1,
  });

  await store.create();

  if (CordovaSQLiteDriver) {
    await store.defineDriver(CordovaSQLiteDriver);
  }

  storage = new LocalListStorage(store);

  return storage;
}
