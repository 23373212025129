import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import { caretUp } from "ionicons/icons";
import React from "react";

export interface PickOptionLabelProps {}

export function PickOptionLabel(props: PickOptionLabelProps) {
  return (
    <IonItem lines="none">
      <IonIcon slot="start" color="primary" size="small" icon={caretUp} />
      <IonLabel color="primary">Pick a random option above</IonLabel>
    </IonItem>
  );
}
