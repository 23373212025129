import React from "react";
import { useDecisionState } from "../../state/CurrentDecisionState";
import { PickOptionLabel } from "./PickOptionLabel";

export interface PickOptionLabelSectionProps {}

export function PickOptionLabelSection(props: PickOptionLabelSectionProps) {
  const isDeciding = useDecisionState((s) => s.isDeciding);
  const hasDecided = useDecisionState((s) => s.hasDecided());

  if (!isDeciding || hasDecided) {
    return null;
  }

  return <PickOptionLabel />;
}
