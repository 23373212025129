import { IonButton, IonIcon } from "@ionic/react";
import { play, refresh } from "ionicons/icons";
import React from "react";

export interface DecidedButtonsProps {
  onRefresh: () => void;
  onNewDecision: () => void;
}

export function DecidedButtons({
  onNewDecision,
  onRefresh,
}: DecidedButtonsProps) {
  return (
    <>
      <IonButton onClick={onRefresh}>
        <IonIcon slot="start" icon={refresh} />
        Again
      </IonButton>
      <IonButton onClick={onNewDecision}>
        <IonIcon slot="start" icon={play} />
        New Decision
      </IonButton>
    </>
  );
}
