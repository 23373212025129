import * as amplitude from "@amplitude/analytics-browser";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect } from "react";
import { AboutButtonSection } from "../components/AboutButton/AboutButtonSection";
import { DecidedButtonsSection } from "../components/DecidedButtons/DecidedButtonsSection";
import { DecidedItemSection } from "../components/DecidedItem/DecidedItemSection";
import { InitialInstructionsSection } from "../components/InitialInstructions/InitialInstructionsSection";
import { LogoWithText } from "../components/Logos/LogoWithText";
import { NewOptionFormSection } from "../components/NewOptionForm/NewOptionFormSection";
import { OptionsListHandle } from "../components/OptionsList/OptionsList";
import { OptionsListSection } from "../components/OptionsList/OptionsListSection";
import { PickOptionLabelSection } from "../components/PickOptionLabel/PickOptionLabelSection";
import { ResetListButtonSection } from "../components/ResetListButton/ResetListButtonSection";
import { SaveListButtonSection } from "../components/SaveListButton/SaveListButtonSection";
import { ShuffleButtonSection } from "../components/ShuffleButton/ShuffleButtonSection";
import classes from "./DecidePage.module.css";

const DecidePage: React.FC = () => {
  let optionsListRef = React.useRef<OptionsListHandle | null>(null);
  let trackedVisitRef = React.useRef<boolean>(false);

  useEffect(() => {
    if (trackedVisitRef.current) {
      return;
    }
    console.log("Tracking visit");
    trackedVisitRef.current = true;
    amplitude.track("Visit Decide Page");
  }, []);

  return (
    <IonPage>
      <IonHeader collapse="fade">
        <IonToolbar style={{ padding: "0.25rem 0" }}>
          <IonTitle>
            <LogoWithText style={{ height: "30px", width: "auto" }} />
          </IonTitle>
          <IonButtons slot="primary">
            <SaveListButtonSection />
            <AboutButtonSection />
          </IonButtons>
          <IonButtons slot="secondary">
            <ResetListButtonSection />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <div className={classes.pageContainer}>
          <div className={classes.pageContainerInner}>
            <InitialInstructionsSection />
            <OptionsListSection ref={optionsListRef} />
            <DecidedItemSection />
          </div>
        </div>
      </IonContent>

      <IonToolbar className={classes.newOptionContainer}>
        <div className={classes.newOptionContainerInner}>
          <NewOptionFormSection />
          <PickOptionLabelSection />
        </div>

        <div className={classes.buttonContainer}>
          <DecidedButtonsSection />

          <ShuffleButtonSection
            onShuffled={() => optionsListRef.current?.shuffleItems()}
          />
        </div>
      </IonToolbar>
    </IonPage>
  );
};

export default DecidePage;
