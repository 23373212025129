import React from "react";
import { useDecisionState } from "../../state/CurrentDecisionState";
import { DecidedItem } from "./DecidedItem";

export function DecidedItemSection() {
  const decided = useDecisionState((s) =>
    s.decision?.decidedItemId
      ? s.decision.options.options.find(
          (o) => o.id === s.decision?.decidedItemId
        )?.name
      : undefined
  );

  if (!decided) {
    return null;
  }

  return <DecidedItem item={decided} />;
}
