import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useRef } from "react";
import { OptionsList } from "../../data/Decision";
import classes from "./InitialInstructions.module.css";
import { LoadOptionsList } from "./LoadOptionsList";

export interface InitialInstructionsProps {
  onListSelected: (list: OptionsList) => void;
  onListDelete: (list: OptionsList) => Promise<void>;
}

export function InitialInstructions({
  onListSelected,
  onListDelete,
}: InitialInstructionsProps) {
  const modal = useRef<HTMLIonModalElement>(null);

  return (
    <>
      <IonModal ref={modal} trigger="open-load-modal">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => modal.current?.dismiss()}>
                Cancel
              </IonButton>
            </IonButtons>
            <IonTitle>Load a list</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <LoadOptionsList
            onListDelete={onListDelete}
            onListSelected={(list) => {
              onListSelected(list);
              modal.current?.dismiss();
            }}
          />
        </IonContent>
      </IonModal>

      <ul className={classes.instructionsList}>
        <li>
          <div>
            Add options or{" "}
            <button className={classes.loadLink} id="open-load-modal">
              Load a list
            </button>
          </div>
        </li>
        <li>
          <div>Click the shuffle button</div>
        </li>
        <li>
          <div>Pick a random option</div>
        </li>
      </ul>
    </>
  );
}
