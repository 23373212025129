import * as amplitude from "@amplitude/analytics-browser";
import React from "react";
import { getStorage } from "../../data/getStorage";
import { useDecisionState } from "../../state/CurrentDecisionState";
import { SaveListButton } from "./SaveListButton";

export interface SaveListButtonSectionProps {}

export function SaveListButtonSection(props: SaveListButtonSectionProps) {
  const { decision, updateOptionList } = useDecisionState();

  if (!decision) {
    return null;
  }

  return (
    <SaveListButton
      defaultName={decision.options.name}
      toUpdate={!!decision.options.firstSavedOn}
      onSave={({ name }) => {
        updateOptionList({ name });

        return getStorage()
          .then((s) => s.saveList({ ...decision.options, name }))
          .then((list) =>
            updateOptionList({ id: list.id, firstSavedOn: list.firstSavedOn })
          )
          .then(() => {
            amplitude.track("Saved List");
          });
      }}
    />
  );
}
