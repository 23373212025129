import React from "react";
import { useDecisionState } from "../../state/CurrentDecisionState";
import { AboutButton } from "./AboutButton";

export interface SaveListButtonSectionProps {}

export function AboutButtonSection(props: SaveListButtonSectionProps) {
  const { decision } = useDecisionState();

  if (decision) {
    return null;
  }

  return <AboutButton />;
}
