import { v4 } from "uuid";
import { OptionsList } from "./Decision";
import { IListStorage } from "./ListStorage";
import { Storage } from "@ionic/storage";

const STORAGE_KEY = "option-lists";

export class LocalListStorage implements IListStorage {
  constructor(private readonly store: Storage) {}

  async deleteList(listId: string): Promise<void> {
    let lists = await this.getLists();
    lists = lists.filter((l) => l.id !== listId);
    await this.store.set(STORAGE_KEY, lists);
  }

  async getLists(): Promise<OptionsList[]> {
    return (await this.store.get(STORAGE_KEY)) ?? [];
  }

  async saveList(list: OptionsList): Promise<OptionsList> {
    let lists = await this.getLists();

    if (!list.id) {
      list.id = v4();
    }

    if (!list.firstSavedOn) {
      list.firstSavedOn = new Date().getTime();
    }

    const exists = lists.find((l) => l.id === list.id);
    lists = exists
      ? lists.map((l) => (l.id === list.id ? list : l))
      : [...lists, list];

    await this.store.set(STORAGE_KEY, lists);

    return list;
  }
}
