import clsx from "clsx";
import React from "react";
import classes from "./LogoWithText.module.css";

export interface LogoTextOnlyProps extends React.SVGProps<SVGSVGElement> {}

export function LogoWithText(props: LogoTextOnlyProps) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width="1107.66"
        height="244.94"
        viewBox="0 0 1107.66 244.94"
        className={clsx(props.className, "light-only")}
        {...props}
      >
        <path
          className={classes.st0}
          d="M272.66,126.1v-3.9h27.47v2.51c0,7.58,4.48,12.46,11.24,12.46,6.45,0,10.92-4.8,10.92-12.46V61h27.48V126.1c0,21.46-15.45,36.45-38.4,36.45C288.59,162.55,272.66,147.62,272.66,126.1Z"
        />
        <path
          className={classes.st0}
          d="M364.58,127.26V83.67h26.31v42.44c0,8.07,4.34,12.81,11.37,12.81s11.28-4.82,11.28-12.81V83.67h26.31v43.59c0,21.37-15,35.29-37.59,35.29S364.58,148.63,364.58,127.26Z"
        />
        <path
          className={classes.st0}
          d="M484.87,162.55c-20.82,0-33.47-11-33.71-27.54h25.32c0,5.58,3.89,8.15,8.88,8.15,3.85,0,7.49-2,7.49-5.91,0-4.4-5.78-5.66-13.12-6.87-11.84-1.92-27.54-5.5-27.54-24,0-15.31,13-25.2,32.62-25.2S516.86,91.51,517.16,107H492.52c0-4.88-3.27-7.15-8-7.15-4.22,0-7.05,2.09-7.05,5.63,0,4.35,5.84,5.36,13.1,6.65,12.06,2.15,28.1,4.65,28.1,24,0,16-13.47,26.41-33.77,26.41Z"
        />
        <path
          className={classes.st0}
          d="M581.74,137v23h-18.1c-16.89,0-27.16-10.33-27.16-27.3V103.93h-14V97.78l34.22-36.45h5.41V83.67h19.19v20.26H562.79v24.31c0,5.47,3.3,8.77,8.85,8.77Z"
        />
        <path
          className={classes.cls2}
          d="M695.38,108.41c0,28.89-21.22,49.59-51.12,49.59H604.19V59h40.07C674.24,59,695.38,79.48,695.38,108.41Zm-28,0C667.41,93.72,657.64,84,643,84H631.59V133H643C657.64,133,667.41,123,667.41,108.41Z"
        />
        <path
          className={classes.cls2}
          d="M787,119.08a48.67,48.67,0,0,1-.54,7H729.72c1.73,8.76,7.29,13.29,15.6,13.29,6.14,0,11.28-2.69,13.81-7.16h26.18c-5.38,17.43-20.83,28.32-40,28.32-23.84,0-41.49-17.42-41.49-40.75s17.51-40.64,41.49-40.64c24.62,0,41.67,17.57,41.67,40Zm-56.79-7.6h30.88c-2.31-7.63-7.81-11.75-15.75-11.75C737.65,99.73,732.32,103.89,730.19,111.48Z"
        />
        <path
          className={classes.cls2}
          d="M836.74,160.5c-24.49,0-42.17-17.17-42.17-40.8s17.59-40.47,42.25-40.47c21.47,0,37.57,13.53,40.44,33.85H851.64a15.52,15.52,0,0,0-14.9-10.28c-9.25,0-15.83,7-15.83,17s6.67,17.14,15.83,17.14a15.38,15.38,0,0,0,15-11.14h25.86c-3,20.74-19.3,34.72-40.81,34.72Z"
        />
        <path
          className={classes.cls2}
          d="M888.91,81.61h26.32V158H888.91Zm.21-31.88H915V72.05h-25.9Z"
        />
        <path
          className={classes.cls2}
          d="M1012.45,54V158H993l-2.48-5.68a35.29,35.29,0,0,1-23,8.26c-23.44,0-40.14-17-40.14-40.77s16.7-40.7,40.14-40.7a35.69,35.69,0,0,1,19,5.3V54Zm-24.38,65.78c0-10-7.24-17.48-17.09-17.48s-17.1,7.5-17.1,17.48,7.24,17.48,17.1,17.48S988.07,129.78,988.07,119.81Z"
        />
        <path
          className={classes.cls2}
          d="M1107.66,119.08a47.51,47.51,0,0,1-.55,7H1050.4c1.72,8.76,7.28,13.29,15.59,13.29,6.15,0,11.28-2.69,13.81-7.17H1106c-5.37,17.44-20.83,28.33-40,28.33-23.83,0-41.49-17.42-41.49-40.75S1042,79.11,1066,79.11c24.62,0,41.67,17.56,41.67,40Zm-56.8-7.6h30.88c-2.31-7.64-7.81-11.76-15.75-11.76C1058.32,99.72,1053,103.89,1050.86,111.48Z"
        />
        <path
          className={classes.st0}
          d="M185.16,39.44,140.46,1.23a5.4,5.4,0,0,0-6.86,0L89.92,39.44c-4.06,3.33-1.77,10.05,3.43,10.05H105.6s-.13,91.81-.37,94.62c-1.9,21.73-13,72-74.14,81.26a82.86,82.86,0,0,0,109-1.76c18.13-16.22,28.09-40.05,28.19-64.75l.47-109.37h13C186.93,49.49,189.22,42.77,185.16,39.44Z"
        />
        <path
          className={classes.cls2}
          d="M243.74,107.63l-37.2-44.85a5.47,5.47,0,0,0-9.79,3.52V82.94H179.3l0,9.58-.19,47.91h17.67v16.64a5.47,5.47,0,0,0,9.79,3.52l37.2-45.9a5.78,5.78,0,0,0,0-7.05Z"
        />
        <path
          className={classes.cls2}
          d="M68.49,198A30.82,30.82,0,0,1,53,171.14c0-16.51,12.7-29.94,28.6-30.65h13c.19-8.15.25-32,.23-57.54H84.56l-5.89.21C34.72,86,0,120.72,0,163.14a77.47,77.47,0,0,0,20.82,52.63l8.71-1.31C46.53,211.89,59.13,205.82,68.49,198Z"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 1107.66 244.94"
        style={
          {
            enableBackground: "new 0 0 1107.66 244.94",
            ...props.style,
          } as Record<string, unknown>
        }
        className={clsx(props.className, "dark-only")}
        {...props}
      >
        <style type="text/css"></style>
        <g>
          <g>
            <g>
              <path
                className={classes.st0}
                d="M272.66,126.1v-3.9h27.48v2.51c0,7.58,4.47,12.46,11.24,12.46c6.44,0,10.92-4.8,10.92-12.46V61.04h27.48     v65.07c0,21.46-15.45,36.45-38.39,36.45C288.59,162.55,272.66,147.62,272.66,126.1z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                className={classes.st0}
                d="M364.58,127.26V83.67h26.31v42.45c0,8.06,4.33,12.81,11.36,12.81s11.28-4.82,11.28-12.81V83.67h26.31v43.59     c0,21.37-15.03,35.29-37.59,35.29C379.69,162.55,364.58,148.63,364.58,127.26z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                className={classes.st0}
                d="M484.87,162.55c-20.82,0-33.47-10.99-33.71-27.54h25.32c0,5.59,3.89,8.16,8.88,8.16     c3.85,0,7.5-1.96,7.5-5.91c0-4.41-5.78-5.66-13.12-6.88c-11.84-1.92-27.54-5.49-27.54-24.02c0-15.31,13.05-25.19,32.62-25.19     c19.45,0,32.06,10.35,32.36,25.82h-24.64c0-4.88-3.28-7.14-8.05-7.14c-4.22,0-7.05,2.09-7.05,5.63c0,4.35,5.84,5.36,13.1,6.65     c12.06,2.14,28.11,4.65,28.11,24.02C518.63,152.11,505.16,162.55,484.87,162.55L484.87,162.55z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                className={classes.st0}
                d="M581.74,137.01v23.04h-18.1c-16.89,0-27.16-10.33-27.16-27.3v-28.82H522.5v-6.15l34.22-36.44h5.4v22.33     h19.19v20.26h-18.53v24.31c0,5.47,3.3,8.77,8.85,8.77H581.74z"
              />
            </g>
          </g>
          <path
            className={classes.st1}
            d="M695.38,108.41c0,28.89-21.22,49.59-51.11,49.59h-40.07V58.98h40.07C674.24,58.98,695.38,79.48,695.38,108.41z    M667.41,108.41c0-14.69-9.77-24.45-24.43-24.45h-11.39v49.05h11.39C657.64,133.02,667.41,123.02,667.41,108.41z"
          />
          <path
            className={classes.st1}
            d="M786.98,119.08c0,2.3-0.2,4.6-0.54,6.97h-56.72c1.72,8.76,7.29,13.29,15.59,13.29   c6.15,0,11.28-2.69,13.81-7.16h26.18c-5.37,17.43-20.83,28.32-39.99,28.32c-23.83,0-41.49-17.42-41.49-40.75   c0-23.42,17.51-40.64,41.49-40.64C769.94,79.11,786.99,96.68,786.98,119.08L786.98,119.08z M730.19,111.48h30.88   c-2.31-7.63-7.81-11.75-15.75-11.75C737.65,99.73,732.32,103.89,730.19,111.48z"
          />
          <path
            className={classes.st1}
            d="M836.74,160.5c-24.5,0-42.18-17.16-42.18-40.8c0-23.64,17.6-40.47,42.26-40.47   c21.47,0,37.57,13.53,40.44,33.85h-25.63c-2.21-6.23-8.04-10.27-14.9-10.27c-9.25,0-15.83,7.03-15.83,16.98s6.66,17.14,15.83,17.14   c7.06,0,12.78-4.32,14.95-11.14h25.85C874.57,146.52,858.25,160.5,836.74,160.5L836.74,160.5z"
          />
          <path
            className={classes.st1}
            d="M888.91,81.61h26.31V158h-26.31V81.61z M889.12,49.73h25.91v22.33h-25.91V49.73z"
          />
          <path
            className={classes.st1}
            d="M1012.45,54.03V158h-19.47l-2.48-5.67c-6.22,5.23-14.14,8.26-23.04,8.26c-23.44,0-40.13-17.04-40.13-40.77   c0-23.74,16.7-40.69,40.13-40.69c7.05,0,13.51,1.9,18.98,5.3V54.03H1012.45z M988.07,119.81c0-9.98-7.24-17.48-17.1-17.48   s-17.1,7.5-17.1,17.48c0,9.97,7.24,17.48,17.1,17.48S988.07,129.78,988.07,119.81z"
          />
          <path
            className={classes.st1}
            d="M1107.66,119.08c0,2.3-0.2,4.6-0.54,6.97h-56.72c1.72,8.76,7.29,13.29,15.59,13.29   c6.15,0,11.28-2.69,13.81-7.16h26.18c-5.37,17.43-20.83,28.32-39.99,28.32c-23.83,0-41.49-17.42-41.49-40.75   c0-23.42,17.51-40.64,41.49-40.64C1090.61,79.11,1107.66,96.67,1107.66,119.08L1107.66,119.08z M1050.86,111.48h30.88   c-2.31-7.63-7.81-11.75-15.75-11.75C1058.32,99.72,1052.99,103.89,1050.86,111.48z"
          />
          <g>
            <g>
              <path
                className={classes.st0}
                d="M185.16,39.44l-44.7-38.2c-1-0.82-2.22-1.23-3.43-1.23c-1.21,0-2.43,0.41-3.43,1.23l-43.68,38.2     c-4.06,3.33-1.77,10.06,3.43,10.06h12.25c0,0-0.13,91.8-0.38,94.62c-1.9,21.73-12.97,72.02-74.13,81.26     c14.57,12.23,33.18,19.57,53.47,19.57c21.25,0,40.66-8.05,55.51-21.34c18.13-16.22,28.09-40.05,28.19-64.75l0.47-109.37h13     C186.93,49.49,189.22,42.77,185.16,39.44z"
              />
            </g>
            <g>
              <path
                className={classes.st1}
                d="M243.74,107.63l-37.2-44.85c-3.24-4.17-9.79-1.82-9.79,3.52v12.58v4.05H179.3l-0.04,9.58l-0.19,47.91     l17.67-0.01v1.39v1.9v13.35c0,5.34,6.55,7.69,9.79,3.52l37.2-45.9c0.8-1.03,1.2-2.28,1.2-3.52     C244.94,109.91,244.54,108.66,243.74,107.63z"
              />
              <path
                className={classes.st1}
                d="M68.49,198.03c-9.23-5.23-15.5-15.29-15.5-26.88c0-16.51,12.69-29.94,28.6-30.65l13.03-0.01     c0.19-8.15,0.25-32.05,0.23-57.54h-10.3l-5.89,0.22C34.72,86.02,0,120.72,0,163.14c0,20.16,7.87,38.55,20.82,52.64l8.71-1.32     C46.53,211.89,59.13,205.82,68.49,198.03z"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
}
