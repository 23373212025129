import React from "react";
import { useDecisionState } from "../../state/CurrentDecisionState";
import { NewOptionForm } from "./NewOptionForm";

export function NewOptionFormSection() {
  const isDeciding = useDecisionState((s) => s.isDeciding);
  const addOption = useDecisionState((s) => s.addOption);
  const hasDecided = useDecisionState((s) => s.hasDecided());

  if (isDeciding || hasDecided) {
    return null;
  }

  return (
    <NewOptionForm
      disabled={isDeciding}
      onSubmit={({ name }) => addOption({ name })}
    />
  );
}
