import { IonContent, IonPage } from '@ionic/react'
import React from 'react'
import classes from './PrivacyPages.module.css'

export interface PrivacyPolicyProps {}

export function AppPrivacyPolicy(props: PrivacyPolicyProps) {
  return (
    <IonPage>
      <IonContent>
        <div className={classes.pageContainer}>
          <div className={classes.pageContainerInner}>
            <h1>Privacy Policy for Just Decide</h1>
            <strong>Effective Date: 18th May 2024</strong>
            <p>
              Welcome to Just Decide! This Privacy Policy is intended to help
              you understand what information we collect, why we collect it, and
              how you can manage your personal information while using our app.
              Our commitment is to your privacy and transparency in all our
              operations. Please read the following terms carefully.
            </p>
            <h2>1. Introduction</h2>
            <p>
              Just Decide, owned and operated by Antomic Studio, is a random
              decision-making app designed to assist you in making choices
              through a variety of tools, including a list selector, a coin flip
              tool, and a random number generator. This Privacy Policy applies
              to your use of the Just Decide app on any device.
            </p>
            <h2>2. Data Collection</h2>
            <p>
              <strong>What We Collect:</strong>
            </p>
            <ul>
              <li>
                <strong>Personal Data:</strong>
                We do not collect personal data like name, email, or contact
                details as our app does not require user registration.
              </li>
              <li>
                <strong>Device Information:</strong>
                We collect information about the device you use to access our
                app, such as the operating system and hardware version, for the
                purpose of improving app performance.
              </li>
              <li>
                <strong>Usage Data:</strong>
                We gather anonymous data about how you interact with our app,
                which helps us identify the parts of our app that are most used
                and those that may need improvement.
              </li>
            </ul>
            <p>
              <strong>Local Storage:</strong>
            </p>
            <ul>
              <li>
                <strong>Decision Data:</strong>
                All decision lists and history logs are stored locally on your
                device and are not transmitted to us or any third parties.
              </li>
            </ul>
            <p>
              <strong>Analytics:</strong>
            </p>
            <ul>
              <li>
                <strong>Google Analytics:</strong> We use Google Analytics to
                collect aggregate, anonymized statistics about user behavior to
                enhance app functionality and user experience.
              </li>
              <li>
                <strong>Sentry:</strong> Sentry is employed to report and
                analyze errors in real-time, helping us maintain app stability
                and address issues promptly.
              </li>
            </ul>
            <h2>3. Use of Data</h2>
            <p>
              The information we collect is used solely for the following
              purposes:
            </p>
            <ul>
              <li>
                To facilitate the functionality of the random decision-making
                tools you use.
              </li>
              <li>
                To improve and optimize our app based on usage trends and device
                compatibility.
              </li>
              <li>
                Maintaining the stability and security of the app through error
                reporting and resolution.
              </li>
            </ul>
            <h2>4. Protection Measures</h2>
            <p>
              We implement standard security measures to protect the information
              collected by our app, especially the data stored locally on your
              device. While we strive to use commercially acceptable means to
              protect your information, please note that no method of electronic
              storage is 100% secure.
            </p>
            <h2>5. User Rights</h2>
            <p>As a user of Just Decide, you have the right to:</p>
            <ul>
              <li>
                Access the data stored locally on your device at any time.
              </li>
              <li>
                Modify or delete any decision lists or logs that you have
                created.
              </li>
            </ul>
            <h2>6. Third-party Affiliations</h2>
            <p>We share data with the following third parties:</p>
            <ul>
              <li>
                <strong>Google Analytics:</strong>
                For gathering usage statistics.
              </li>
              <li>
                <strong>Sentry:</strong>
                For error reporting purposes.
              </li>
            </ul>
            <p>
              If additional third-party services are used in the future, this
              policy will be updated, and you will be informed of how your data
              is managed.
            </p>
            <h2>7. Age Restrictions</h2>
            <p>
              Just Decide is not intended for use by individuals under the age
              of 13. We do not knowingly collect or solicit data from anyone
              under this age limit. If we learn that we have collected personal
              information from a child under age 13, we will delete that
              information as quickly as possible.
            </p>
            <h2>8. Policy Changes</h2>
            <p>
              We may update this Privacy Policy from time to time. If we make
              changes that materially alter your privacy rights, we will provide
              additional notice, such as via email or through the app itself.
              Your continued use of Just Decide after such modifications will
              constitute your: (a) acknowledgment of the modified Policy; and
              (b) agreement to abide and be bound by that Policy.
            </p>
            <h2>9. Contact Information</h2>
            <p>
              If you have any questions or concerns about this Privacy Policy or
              our treatment of your information, please contact us at:
            </p>
            <p>
              Email: <a href="mailto:info@antomic.net">info@antomic.net</a>
            </p>
            <p>
              Thank you for choosing Just Decide. We hope our app makes your
              decision-making process a little easier and a lot more fun!
            </p>
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}
