import { IonButton, IonIcon } from "@ionic/react";
import { dice } from "ionicons/icons";
import React from "react";

export interface DecideButtonProps {
  onClick: () => void;
  disabled: boolean;
}

export function ShuffleButton({ onClick, disabled }: DecideButtonProps) {
  return (
    <IonButton disabled={disabled} onClick={onClick} style={{ width: "100%" }}>
      <IonIcon slot="start" icon={dice} />
      Shuffle
    </IonButton>
  );
}
