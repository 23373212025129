export function randomArrayIndex(arr: unknown[]) {
  return Math.floor(Math.random() * arr.length);
}

export function shuffleWith<T>(
  arr: T[],
  swap: (a: T, b: T, aIndex: number, bIndex: number) => void
) {
  for (let i = arr.length - 1; i > 0; i--) {
    const randomIndex = randomArrayIndex(arr);
    swap(arr[i], arr[randomIndex], i, randomIndex);
  }
}

export function shuffleTimeoutWith<T>(
  arr: T[],
  swap: (a: T, b: T, aIndex: number, bIndex: number) => void,
  timeoutMs: number
) {
  let timeouts: NodeJS.Timeout[] = [];

  for (let i = arr.length - 1; i > 0; i--) {
    const timeout = setTimeout(() => {
      const randomIndex = randomArrayIndex(arr);
      swap(arr[i], arr[randomIndex], i, randomIndex);
    }, timeoutMs);
    timeouts.push(timeout);
  }

  return timeouts;
}

export function swapItems<T>(arr: T[], a: number, b: number) {
  const tmp = arr[a];
  arr[a] = arr[b];
  arr[b] = tmp;
}
