import { MutableRefObject, useState } from "react";
import { shuffleTimeoutWith, swapItems } from "../../utils/array";
import { SHUFFLE_COUNT, SHUFFLE_PERIOD_MS } from "./Constants";

export interface UseShuffleItemsInput {
  itemRefs: MutableRefObject<HTMLElement[]>;
}

export function useShuffleItems({ itemRefs }: UseShuffleItemsInput) {
  const [lastShuffleCleanup, setLastShuffleCleanup] = useState<
    (() => void) | null
  >(null);

  const isShuffling = !!lastShuffleCleanup;

  const shuffleItems = () => {
    lastShuffleCleanup?.();

    const itemRefsCopy = [...itemRefs.current];

    let timeouts: NodeJS.Timeout[] = [];

    for (let shuffleCount = 0; shuffleCount < SHUFFLE_COUNT; shuffleCount++) {
      timeouts.push(
        ...shuffleTimeoutWith(
          itemRefsCopy,
          (aRef, bRef, a, b) => {
            const aBottom = aRef.style.bottom;
            aRef.style.bottom = bRef.style.bottom;
            bRef.style.bottom = aBottom;
            swapItems(itemRefsCopy, a, b);
          },
          (1 + shuffleCount) * SHUFFLE_PERIOD_MS
        )
      );
    }

    setTimeout(() => {
      setLastShuffleCleanup((s) => {
        if (s === cleanup) {
          return null;
        }
        return s;
      });
    }, (1 + SHUFFLE_COUNT) * SHUFFLE_PERIOD_MS + 20);

    const cleanup = () => {
      timeouts.forEach(clearTimeout);
      setLastShuffleCleanup((c) => (c === cleanup ? null : c));
    };
    setLastShuffleCleanup(() => cleanup);
  };

  return { shuffleItems, isShuffling };
}
